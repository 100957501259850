import { Link } from 'react-router-dom';
import { SRLWrapper }  from 'simple-react-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

function CardItem(props) {

  const options = {           /*LightBox Wrapper Settings */
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 1px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px']
    },
    buttons: {
      showNextButton: false,
      showPrevButton: false,
      showAutoplayButton: false,
    }
  }

  return (
    </* CardItem Component Setup */>
   
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
        <SRLWrapper options={options}>                                            
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            < img 
              className='cards__item__img'
              src={props.src}
            />      
          </figure>
        </SRLWrapper>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
        
      </li>
      
    </>
  
  );
}

export default CardItem;