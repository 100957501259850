import React, {useState} from 'react';
import './Cards.css';
import CardItem from './CardItem';


function Cards() {

  const [toggler, setToggler] = useState(false);

  return (
    <div className='cards'>
      <h1 className="ProjectTitle">Here are some projects that I have completed</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
       
          <ul className='cards__items'>
          
         
            <CardItem
              
              src='images/img-1.jpg'
              text='Entertainment Cabinet⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀'
              path='/Gallery'
            
            />
           
          
            <CardItem
              src='images/img-2.jpg'
              text='Outdoor Deck Chair⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀'
              path='/Gallery'
            />
             <CardItem
              src='images/img-3.jpg'
              text='Outdoor Round Table With Benches⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀'
              path='/Gallery'
            />
             
          </ul>
         
          <ul className='cards__items'>
            <CardItem
              src='images/img-4.jpg'
              text='Outdoor Prep Table'
              path='/Gallery'
            />
            <CardItem
              src='images/img-5.jpg'
              text='Side Table w/Drawer and Shelf'
              path='/Gallery'
            />
            <CardItem
              src='images/img-6.jpg'
              text='Modern Bookcase'
              path='/Gallery'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-7.jpg'
              text='Bedroom Dresser'
              path='/Gallery'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Knickknack Wall Holder'
              path='/Gallery'
            />
            <CardItem
              src='images/img-9.jpg'
              text='Childrens Play Stand' 
              path='/Gallery'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;